
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@import '../../../style/globalColorVars.scss';
@import '../../../style/grids.scss';
@import '~bootstrap/scss/mixins';

// barfstrap media queries
//@include media-breakpoint-up(sm)
//@include media-breakpoint-up(md)
//@include media-breakpoint-up(lg)
//@include media-breakpoint-up(xl)
//@include media-breakpoint-up(xxl)

.card-image-el {
 // Adds background tramlines for any 4x3 image being rendered
 background-color: $background-alt;
}

// Corrects image sizing for homepage summits & series grids
// :not(.featured-section) is to ensure we don't expose the rules for the
// featured image section on series page.
.card-image-el:not(.section-featured) {
 width: 100%;
 max-width: 100%;
 height: auto;
}

.card-image-el.search-page.series,
.card-image-el.search-page.summits {

 width: 100%;
//height: 220px;

 @include media-breakpoint-up(sm) {

  width: 334px;
  height: 190px;
 }

  object-fit: cover;
}
