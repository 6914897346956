
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@import '../../../style/globalColorVars.scss';
@import '../../../style/grids.scss';
@import '~bootstrap/scss/mixins';

.card-image-el {
 // Adds background tramlines for any 4x3 image being rendered
 background-color: $background-alt;
 width: 100%;
 max-width: 100%;
 height: auto;
}

.card-image-el.logo {

}
// class full is one of a pair 'full' / 'logo' to help know if
// we're rendering a large main placeholder or image or the company logo.
.full {
 position: relative;
}

.card-image-el.series.logo,
.card-image-el.summits.logo {

 height: 28px;
}

.search-page.series.card-image-el,
.search-page.summits.card-image-el {

 width: 100%;
 //height: 200px;

 @include media-breakpoint-up(sm) {

  width: 334px;
  height: 190px;
 }

  object-fit: cover;
}

