
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@import '../../style/globalColorVars.scss';
@import '../../style/font-family.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.loadmore-container {
  padding-left: 10px;
  padding-right: 10px;
}

.loadmore-line {
  border-bottom: 1px solid $border-divider-color;
  width: 100%;

  &:focus {
    border-bottom-color: $border-divider-color-hover;
    .load-icon-bg {
      background-color: $border-color-focus;
      border-radius: 26px;
    }
    svg {
      color: $neutral-text;

      &.loading-spinner {
        background: none;
      }
    }
  }

  &:hover {
    border-bottom-color: $border-divider-color-hover;

    .load-icon-bg {
      background: $icon-light-grey;
      border-radius: 26px;
    }

    svg {
      color: $neutral-text;

      &.loading-spinner {
        background: none;
      }
    }
  }
}

.loadmore-btn-container {
  background: 0 0;
  border: 0;
  display: block;
  position: relative;
  bottom: -37px;
  height: 40px;
  width: 100%;
}

.loadmore-icon-container {
  background-color: $bt-white;
  display: inline-block;
  position: relative;
  padding: 10px;
  vertical-align: middle;
  bottom: 28px;

  .load-icon-bg {
    box-sizing: content-box;
    padding: 8px;
    height: 26px;
    width: 26px !important;
    background-color: $bt-white;
    position: relative;
    display: block;
  }

  svg {
    font-size: 26px;
    position: relative;
    margin: 2px auto 0 auto;
  }
}

@include media-breakpoint-up(lg) {
  .talks-grid-search-loadmore,
  .search-summit-item-loadmore,
  .search-series-item-loadmore,
  .search-channels-item-loadmore {
    width: 70%;
    margin-left: 0;
    padding: 0 12px;
  }
}
