
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@import '../../style/globalColorVars.scss';
@import '../../style/font-family.scss';
@import '../../style/grids.scss';

// For margin styles on the first block that have to be adjusted
// see the global.scss file for rulesets maintained there
// due to need to target classes/elements outside of tightly scoped
// index.module styles.

.bt-section-headings {
  margin: 60px 0 20px 0;

  // on a page with carousel / filter buttons it has padding bottom 30px
  // so we'll pass a class to remove heading top margin altogether
  // Currently we can't set this class programatically

  &.no-margin-top {
    margin-top: 0;
  }

  &.no-subscribed-channels,
  &.no-watch-later,
  &.no-recently-viewed {
    margin-top: 0;
  }

  h2 {
    margin: 0;
  }

  p {
    font-size: 16px;
    margin-top: 10px;

    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// This is required to counteract the skeleton rules in global.scss affectin the topics container block
// issue here is ideally skeleton blocks need to include the same markup structure
// that would require a reletively deep dive restructuring files.
// Or removing all rules and refactoring headers.banners carousel buttons to hold the bottom margin.
.bt-section-headings.topics-heading {
  margin-top: 60px !important;
}

.bt-section-headings-link {
  padding-left: 10px;

  &:hover {
    text-decoration: none !important;
    color: $button-primary-hover-color;
  }

  &:focus {
    color: $button-primary-focus-color;
  }

  svg {
    font-size: 30px;
    height: 16px;
    color: $link-primary;
  }
}

.bt-section-headings-icon {
  color: $link-primary;
  font-size: 30px;
  height: 16px;
  margin-left: 6px;
}
